var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('my-header-view',{attrs:{"title":"Material de Empaque","bread-crumbs":_vm.breadCrums}}),(_vm.loading)?_c('my-loader',{attrs:{"title":"Un momento por favor","subtitle":"Estamos obteniendo los materiales de empaque existentes."}}):_c('v-row',{staticClass:"pt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0 mt-5 soft_shadow",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"sort-by":"sinv_pro_codigo","items-per-page":5,"header-props":{ 'sort-by-text': 'Ordenar por' },"footer-props":{
          'items-per-page-text': 'Materiales de empaque por página',
          'items-per-page-all-text': 'Todos',
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('my-table-top',{attrs:{"enableSearch":""},on:{"handleSearch":_vm.handleSearch},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('sync-info',{attrs:{"title":"materiales de empaque","loading":_vm.loading,"id":"materialEmpaque/findProductosMaterialEmpaque"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('my-table-options',{attrs:{"include-report":""},on:{"optionReport":_vm.createReport,"optionCreate":_vm.openCreateForm}})],1)],1)]},proxy:true}])})]},proxy:true},{key:"no-data",fn:function(){return [_c('my-table-no-data',{attrs:{"title":"¡Lo sentimos!","text":"Aun no existe algun producto de Material de Empaque.","button":"","button-text":"Agregar"},on:{"button-data-action":_vm.openCreateForm}})]},proxy:true},{key:"no-results",fn:function(){return [_c('my-table-no-data',{attrs:{"title":"¡Lo sentimos!","text":"No encontramos ningun producto de Material de Empaque."}})]},proxy:true},{key:"item.sadm_eliminado",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.eliminado ? "Eliminado" : "Activo")+" ")]}},{key:"item.sadm_fecha_creado",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.milisecondsToDate(item.sadm_fecha_creado))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('my-table-item-actions',{attrs:{"item":item},on:{"edit-item":_vm.editItem,"enable-item":_vm.enableItem,"disable-item":_vm.disableItem}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }